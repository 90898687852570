import bind from "bind-decorator";
import { IGroupSignaling } from "../base/iMsSignaling";
import { MsClient } from "../base/msClient";
import { PresenceService } from "../../rabbitmq/presenceService";
import { Logger } from "../../../helpers/logger";
import { LocalEndpoint } from "./localEndpoint";
import { makeObservable, observable, reaction, runInAction } from "mobx";
import { EmulatorRemoteEndpoint, ScreenState } from "./emulatorRemoteEndpoint";
import { HardwareState, DisplayPowerMode, WebcamState, GnssPowerMode, EmulatorClipboard } from "../../state/emuHardwareState";
import { localEndpointState } from "../../state/localEndpointState";
import { RemoteEndpoint } from "./remoteEndpoint";

enum DataTypeLabel {
    KEYBOARD = 1,
    MOUSE = 2,
    TOUCH = 3,
    CLIPBOARD = 4,
    VMCMD = 5,
    EVDEV = 6,
    GPS = 7
}

export enum KeyEventType {
    KEYDOWN = 0,
    KEYUP = 1,
    KEYPRESSED = 2,
};

export interface IEmulator {
    sendKeyboard(opts: { key?: string, keyCode?: number, eventType: KeyEventType }): void;
    sendMouse(event: any, buttons: number): void;
    sendTouches(touching: React.TouchList, left: React.TouchList, event: React.TouchEvent<HTMLElement>): void;
}

export class RemoteEndpointState {
    // don't change name as we're relying on it when receiving the data from the emu
    @observable hardwareState: HardwareState = new HardwareState()

    update(newRemoteEndpointState: RemoteEndpointState) {
        if (newRemoteEndpointState) {
            this.hardwareState.update(newRemoteEndpointState.hardwareState)
        }
    }
}

export class EmulatorRemoteEndpoint2 extends EmulatorRemoteEndpoint {
    
    @observable public remoteEndpointState = new RemoteEndpointState();
    private gnssHandle: number = -1
    private sendGpsInterval: ReturnType<typeof setInterval> | undefined;
    
    override get displayIsOff(): boolean {
        if (this.remoteEndpointState.hardwareState.displayPowerMode == DisplayPowerMode.Unknown) {
            return this.smartphoneState.screenState == ScreenState.OFF
        }
        return this.remoteEndpointState.hardwareState.displayPowerMode == DisplayPowerMode.Off
    };

    override get displayIsOn(): boolean {
        if (this.remoteEndpointState.hardwareState.displayPowerMode == DisplayPowerMode.Unknown) {
            return this.smartphoneState.screenState == ScreenState.ON
        }        
        return this.remoteEndpointState.hardwareState.displayPowerMode == DisplayPowerMode.On
    };

    override get anyWebcamIsOn(): boolean {
        return this.remoteEndpointState.hardwareState.backWebcamState === WebcamState.On || 
            this.remoteEndpointState.hardwareState.frontWebcamState === WebcamState.On
    }

    override get anyMicrophoneIsOn(): boolean {
        return this.remoteEndpointState.hardwareState.activeMicrophones.length > 0
    }

    /* All Emu events should end up here */
    @bind
    private async onEmuEvent_v2(senderId: string, data: any) {
        this.logger.debug('onEmuEvent_v2', senderId, data)

        if (data.hardwareState) {
            runInAction(() =>{
                this.remoteEndpointState.update(data)
            })
        } else if (data.sender === "app") {
            await this.emuEvent_AppRequest(data)
        }
    }

    protected override registerEvents(groupSignaling: IGroupSignaling) {
        super.registerEvents(groupSignaling)
        groupSignaling.on([ ...this.aid, 'emuEvent_v2'], this.onEmuEvent_v2)
    }

    protected override unregisterEvents(groupSignaling: IGroupSignaling) {
        super.unregisterEvents(groupSignaling)
        groupSignaling.off([ ...this.aid, 'emuEvent_v2'], this.onEmuEvent_v2)
    }
    
    constructor(public readonly id: string,
        // public readonly sharedData: any,
        // public readonly producers: ProducerInfo[],
        protected readonly msClient: MsClient,
        protected readonly groupSignaling: IGroupSignaling,
        protected readonly presenceService: PresenceService,
        protected readonly localEndpoint: LocalEndpoint,
        public autoConsumeProducers: boolean = true) {
            super(id, msClient, groupSignaling, presenceService, localEndpoint, autoConsumeProducers)
            makeObservable(this)
            this.logger = new Logger(`EmuRemoteEndpoint2_${id}`)

            const handleCamera = (webcamState: WebcamState, webcamId: string) => {
                if (webcamState === WebcamState.On) {
                    // hxw hardcoded for simplicity reasons, but would be nice to get them from the emu
                    // also, this only properly works on desktops, we should adapt it for mobile
                    this.startWebcam(webcamId, localEndpointState.videoInputDeviceId, 480, 480)
                } else if (webcamState === WebcamState.Off) {
                    this.stopWebcam(webcamId)
                }
            }

            reaction(() => this.remoteEndpointState.hardwareState.frontWebcamState,
                (value, prevValue, reaction) => {
                    handleCamera(value, HardwareState.frontWebcamId)
                })
            reaction(() => this.remoteEndpointState.hardwareState.backWebcamState,
                (value, prevValue, reaction) => {
                    handleCamera(value, HardwareState.backWebcamId)
                })
            reaction(() => this.remoteEndpointState.hardwareState.openedMicrophones,
                (microphones, prevValue, reaction) => {
                    microphones.forEach(microphone => {
                        this.startMicrophone(microphone, localEndpointState.audioInputDevice?.deviceId)
                    });
                })
            reaction(() => this.remoteEndpointState.hardwareState.closedMicrophones,
                (microphones, prevValue, reaction) => {
                    microphones.forEach(microphone => {
                        this.stopSending(microphone)
                    });
                })
            
            reaction(() => localEndpointState.audioInputDevice, 
                (deviceId, prevValue, reaction) => {
                    if (this.producingAnyMicrophone) {
                        this.remoteEndpointState.hardwareState.openedMicrophones.forEach(m => {
                            this.replaceMicrophoneDevice(m, deviceId?.deviceId)
                        })
                    }
                })
            reaction(() => localEndpointState.videoInputDevice, 
                (device, prevValue, reaction) => {
                    if (this.producingAnyWebcam) {
                        if (this.remoteEndpointState.hardwareState.frontWebcamState === WebcamState.On) {
                            this.replaceWebcam(HardwareState.frontWebcamId, device?.deviceId, 480, 480)
                        } 
                        if (this.remoteEndpointState.hardwareState.backWebcamState === WebcamState.On) {
                            this.replaceWebcam(HardwareState.backWebcamId, device?.deviceId, 480, 480)
                        }
                    }
                })
            reaction(() => this.remoteEndpointState.hardwareState.gnssState,
                (gnssState, prevGnssState, reaction) => {
                    this.handleGnss(gnssState)
                })
            reaction(() => this.remoteEndpointState.hardwareState.clipboard,
                (clipboard, prevClipboard, reaction) => {
                    this.handleEmulatorClipboard(clipboard)
                })
        }


    @bind
    protected handleGnss(state: GnssPowerMode) {
        if (state === GnssPowerMode.Off) {
            if (this.gnssHandle !== 0) {
                navigator.geolocation.clearWatch(this.gnssHandle)
                this.gnssHandle = 0
                if (this.sendGpsInterval) {
                    clearInterval(this.sendGpsInterval)
                }
            }
        }
        if (state === GnssPowerMode.On) {
            // sometimes the emu requests the gps even if the screen is off - don't know why right now, which might confuse the u
            if (this.remoteEndpointState.hardwareState.displayPowerMode === DisplayPowerMode.On) {
                this.gnssHandle = navigator.geolocation.watchPosition((position) => {
                    this.logger.debug('Gps got position', position)
                    this.sendGps(position);
                    if (this.sendGpsInterval) {
                        clearInterval(this.sendGpsInterval)
                    }
                    this.sendGpsInterval = setInterval(() => this.sendGps(position), 5000)
                }, (error) => {
                    this.logger.error('Gps position error', error)
                    // on error send the position of the Kapellbrücke in Lucerne
                    this.sendGps({
                        timestamp: Date.now(),
                        coords: {
                            latitude: 47.05181180789558,
                            longitude: 8.307563290503847,
                            accuracy: 100,
                            altitude: null,
                            altitudeAccuracy: null,
                            heading: null,
                            speed: null
                        }
                    })
                }, 
                { 
                    enableHighAccuracy: true,
                    maximumAge: 30000,
                });
            }
        }
    }

    @bind
    protected async handleEmulatorClipboard(remoteClipboard: EmulatorClipboard) {
        this.logger.debug('received EmulatorClipboard', remoteClipboard)
        this.emit(RemoteEndpoint.CLIPBOARD_COPY, remoteClipboard)
    }

    public override close(): void {
        super.close()
        this.logger.debug('closing')
        this.unregisterEvents(this.groupSignaling)
        // stop all microphones if needed
        this.stopSendingMedia();
        this.disposers.forEach(d => d())
    }
}
